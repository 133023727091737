<template>
  <section class="full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb1rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">SMS Versturen</div>
        <b-button class="MyButton" @click="Unlock">{{ isUnlocked ? 'Lock' : 'Unlock' }}</b-button>
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">{{ UnlockedTimeMsg }}</div>
      </div>
    </div>
    
    
    <div v-if="isUnlocked" >
      <div class="columns is-multiline is-centered is-gapless is-marginless">
        <div class="column is-11 is-offeset-1 mt1rem" v-for="(queue, key) in queueList" v-bind:key="`queue_${key}`">
          QUEUE AT: {{DDMMYYYY_HHii(new Date(queue.sendAt))}} => {{queue.okNrList.length}}x - {{ queue.smsText }}
          <b-button class="button is-primary is-small" @click="loadQueueItem(queue)" style="margin-left: 15px; margin-right: 15px">View</b-button>  
          <b-button class="button is-primary is-small" @click="cancelQueueItem(queue)" style="margin-left: 15px; margin-right: 15px">Cancel</b-button>  
        </div>          
        
      </div>
      
      
      <div class="columns is-multiline is-centered is-gapless is-marginless">
        
        <b-field label="SMS list unfiltered" class="column is-2 is-offset-2 m1rem">
          <b-input maxlength="20000000" v-model="unfilteredList" type="textarea" custom-class="inputheight1" @input="filterSMSListInput"></b-input>
          <b-button v-show="unfilteredList !== unfilteredListVerification" class="button is-primary is-small m1rem"  @click="importFilterSMSList">Import List</b-button>
        </b-field> 
        
        <div class="column is-3 m1rem">
          <div v-if="unfilteredList === unfilteredListVerification && filteredSMSListInvalid.length > 0" label="Result:" >
            <label class="label">Invalid: {{ filteredSMSListInvalid.length }} numbers</label>
            <b-input maxlength="20000000" type="textarea" disabled custom-class="inputheight3" :value="filteredSMSListInvalid.join(`\r\n`)"> 
            </b-input>
          </div>    
          
          <div v-if="unfilteredList === unfilteredListVerification && filteredSMSList.length > 0" label="Result:">
            <label class="label">Valid: {{ filteredSMSList.length }} unique numbers</label>
            <b-input maxlength="20000000" type="textarea" disabled custom-class="inputheight1" :value="filteredSMSList.join(`\r\n`)"> 
            </b-input>
          </div>    
        </div>
        
        <div v-if="unfilteredList === unfilteredListVerification && filteredSMSList.length > 0" label="Select Admin:" class="column is-5 is-offset-1 m1rem">
          <label class="label">Admin</label>
          <b-field>
            <b-select multiple size="is-small" native-size="5" v-model="selectedAdmin">
                <option value="0031645472098">Chris 0031645472098</option>
                <option value="0031647047004">Kasper 0031647047004</option>
                <option value="0031648504031">Richard 0031648504031</option>
                <option value="0031624894879">Djody 0031624894879</option>
                <option value="0031611710139">Sander 0031611710139</option>
            </b-select>
          </b-field>
          
          <div class="spacer"></div>
          
          <label class="label">Text</label>
          <b-input maxlength="2000" v-model="smsText" type="textarea" custom-class="inputheight4"></b-input>
          <b-button class="button is-primary is-small m1rem" @click="verstuurTestSMS">Verstuur TEST SMS</b-button>
          
          
          
          <div v-if="testSMSSend">
            
            <div class="spacer"></div>
            
            <b-field label="Select datetime">
              <b-switch v-model="enableScheduledSMS">Schedule</b-switch>
              <b-datetimepicker
                  v-if="enableScheduledSMS"
                  v-model="scheduledSendDatetime"
                  rounded
                  placeholder="Click to select..."
                  icon="calendar-today"
                  :icon-right="scheduledSendDatetime ? 'close-circle' : ''"
                  icon-right-clickable
                  @icon-right-click="clearDateTime"
                  :locale="locale"
                  :first-day-of-week="firstDayOfWeek"
                  :datepicker="{ showWeekNumber }"
                  :timepicker="{ enableSeconds, hourFormat }"
                  horizontal-time-picker>
              </b-datetimepicker>
            </b-field>
            
            <label class="label">Verification code</label>
            <b-input maxlength="4" type="number" v-model="smsVerificationCode"></b-input>
            
            <b-button class="button is-primary is-small m1rem" @click="verstuurSMS">Verstuur sms</b-button>
            
          </div>
          
        </div> 
        
        
      </div>
      
    </div>
    
    <div v-if="isUnlocked && unfilteredList === unfilteredListVerification && filteredSMSList.length > 0" class="example_messages">
        <h1>Example messages</h1>
        
        <p>Reminder: om 15:00 begint de webinar 'WaarneemApp - Implementatie Vrij Roosteren voor Huisartsenposten' live. Uw deelnamelink is gemaild. Mvg MedischeScholing.nl</p>
  
        <p>Reminder: om 20:00 begint de 'Webinar WTZA van de Landelijke Huisartsen Vereniging'. Uw deelnamelink is gemaild. Geen link? Mail info@medischescholing.nl</p>
                
        <p>Het congres van Verenso start om 14.30 uur. Om door te gaan naar het congres kunt gebruik maken van de APARTE link die u heeft gekregen in de mail. Als u reeds nar de ALV kijkt, dan kunt u ook klikken 'Ga naar uitzending' waar u nu kunt chatten en stemmen.</p>
        
        <p>Cohesie Vrij Roosteren Fase 3A - Helaas heeft u te weinig diensten op uw naam staan om het rooster te sluiten. Je valt daarmee onder de groep huisartsen die het minst aantal diensten doet van de hele groep gecommitteerde huisartsen - de worst performer. Om te voorkomen dat u diensten toebedeeld krijgt willen we u vragen om openstaande diensten aan uzelf toe te wijzen. U heeft hierover een mail ontvangen.</p>  
        
        <p>Cohesie - Beste huisarts, u wordt nog steeds gemarkeerd als worst performer. U heeft vandaag tot 12:00 om vrijwillig nog diensten op te pakken. U heeft vorige week een mail ontvangen waarin het aantal uren wordt gespecificeerd.</p>
        
        <p>Cohesie - Beste huisarts, Goed nieuws! Het rooster van Cohesie is volledig gevuld. Alle diensten zijn inmiddels verdeeld. U heeft een mail ontvangen met een overzicht.</p>
        
        <p>Reminder: om 20:00 begint de webinar 'Vrij roosteren'. Uw deelnamelink is gemaild. Geen link? Mail info@medischescholing.nl</p>      
        
        <p>Beste huisarts, het rooster is gepubliceerd. Excuses voor de vertraging. U kunt uw toegewezen diensten inzien. Vanaf morgen 17:30 kunt u diensten direct aan uzelf toewijzen.</p>
        
        <p>Reminder: om 17:00 begint de 'Live Talkshow - De huisartsenzorg draait door!'. Uw deelnamelink is gemaild. Geen link? Mail info@medischescholing.nl</p>      
    </div>
       
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
import axios_api from '@/plugins/axios_api';
import { API_ROOT } from '@/config/app.js';

import { Calc_TimeLeftMsg }         from '@/helpers/functions';
// import { API_ROOT } from '@/config/app.js';
// import { mapState } from 'vuex';
import { DDMMYYYY_HHii } from '@/helpers/dates';

export default {
  name: "EmptyPage", 
  
         
  data() {
    return {
      isUnlocked          : false,
      UnlockedTimeMsg     : '',
      
      pageLoading         : false,
      pageLoadingTimeout  : null,
      
      unfilteredList      : `645472098
0031645472098
003164547209
+31645472098`,
      unfilteredListVerification: "",
      filteredSMSList     : [],
      filteredSMSListInvalid: [],
      selectedAdmin       : ["0031645472098"],
      testSMSSend         : false,
      smsVerificationCode : '',
      
      smsText             : "Reminder: om 15:00 begint de webinar 'WaarneemApp - Implementatie Vrij Roosteren voor Huisartsenposten' live. Uw deelnamelink is gemaild. Mvg MedischeScholing.nl",
      
      LockStatus : {
        unlocked: false,
        Locktime: 0,
      },
      
      enableScheduledSMS: false,
      scheduledSendDatetime: new Date(),
      showWeekNumber: false,
      enableSeconds: false,
      hourFormat: '24', // Browser locale
      locale: 'nl-NL', // Browser locale
      firstDayOfWeek: 1, // 0 - Sunday
      
      queueList: []
    }
  },
  
  async mounted(){
   // this.ShowPageLoading(15000);
    await this.UpdateLockStatus();
    await this.getQUEUE();
    // this.GetTableStructureData();
    
    const _this = this;
    setInterval(() => {
      _this.CheckLockStatus();
    }, 1000);
    
    
    
  },
  
  methods: {
    DDMMYYYY_HHii,
    
    async getQUEUE() {
      if (this.isUnlocked !== true) return false
      
      await this.ShowPageLoading(100)      
      let response = await axios_api.get(`${API_ROOT}/monitor/sms/queue`);
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get filtered sms queue', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      console.info(`response.data:`, response.data.payload)      
      this.queueList = response.data.payload.queueList
    },
    
    async UpdateLockStatus() {
      const LockStatus = await this.$store.dispatch('databaseactions/DB_GetLockStatus');      
      // console.info(`LockStatus:`, LockStatus);
      this.SetUnlockStatus(LockStatus);
    },   
     
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    async Unlock() {
      const _this = this;
      
      this.$buefy.dialog.prompt({
          message: `2FA Code`,
          inputAttrs: {
            placeholder: '2FA Code',
            maxlength: 6
          },
          trapFocus: true,
          onConfirm: async (value) => {
            this.$buefy.toast.open(`2FA Code: ${value}`);
            let rtn = await _this.$store.dispatch('databaseactions/DB_SetLockStatus', { twoFA : value });
            console.info(`rtn:`, rtn);
            
            _this.SetUnlockStatus(rtn);
          }
      })
    },
    
    SetUnlockStatus(LockStatus){
      if (LockStatus === false || LockStatus === null) {
        this.LockStatus = {
          unlocked: false,
          Locktime: 0,
        }
        this.CheckLockStatus();
        return;
      }
      
      this.LockStatus = LockStatus;
      if (LockStatus.unlocked === true) {
        // this.GetTableStructureData();
      }
      this.CheckLockStatus();
    },
    
    filterSMSListInput(){
      // console.info(`input...:`, this.unfilteredList)     
      this.filteredSMSList = []
      this.testSMSSend = false
      this.smsVerificationCode = ''
    },
    
    async importFilterSMSList(){
      await this.ShowPageLoading(100)      
      this.testSMSSend = false
      this.smsVerificationCode = ''
      
      let response = await axios_api.post(`${API_ROOT}/monitor/sms/filterList`, {
        unfilteredList: `||startList||\n${this.unfilteredList}\n||endList||`
      });
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get filtered sms list', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      console.info(`response:`, response)
      this.unfilteredListVerification   = this.unfilteredList
      this.filteredSMSListInvalid       = response.data.payload.errorList
      console.info(`this.filteredSMSListInvalid:`, this.filteredSMSListInvalid)
      this.filteredSMSList              = response.data.payload.okNrList
      console.info(`this.filteredSMSList:`, this.filteredSMSList)
      this.HidePageLoading()
    },
    
    async verstuurTestSMS(){
      await this.ShowPageLoading(5*1000)      
      let response = await axios_api.post(`${API_ROOT}/monitor/sms/test`, {
        unfilteredList: `||startList||\n${this.unfilteredList}\n||endList||`,
        adminList: this.selectedAdmin,
        smsText: this.smsText
      });
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to send test sms', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.testSMSSend = true
      this.HidePageLoading()
    },
    
    async verstuurSMS(){
      if (this.smsVerificationCode === '' || this.smsVerificationCode.trim().length !== 4) {
        return this.$buefy.dialog.alert({ title: 'Error', message: 'sms verification code missing', type: 'is-danger' })
      }
      
      const _this = this;      
      this.$buefy.dialog.confirm({
        title: `are you sure ?`,
        message: `SMS versturen naar ${_this.filteredSMSList.length} nr's?`,
        onConfirm: async () => {
          _this.verstuurSMSConfirm()
        },
        cancelText: 'No',
        confirmText: 'YES, send the SMS'          
      })
    },
    
    async verstuurSMSConfirm(){
      
      if (this.enableScheduledSMS) {
        const curDate = new Date()
        if (!this.scheduledSendDatetime) {
          return this.$buefy.dialog.alert({ title: 'Error', message: 'scheduled time incorrect', type: 'is-danger' })
        }
        if (this.scheduledSendDatetime.getTime() < curDate.getTime()){
          return this.$buefy.dialog.alert({ title: 'Error', message: 'scheduled time less than curTime', type: 'is-danger' })
        }
      }
      // return this.$buefy.dialog.alert({ title: 'SMS is scheduled to send', message: `SMS will send at ${DDMMYYYY_HHii(this.scheduledSendDatetime)}`, type: 'is-info' })
      
      await this.ShowPageLoading(5*1000)      
      let response = await axios_api.post(`${API_ROOT}/monitor/sms/versturen`, {
        unfilteredList: `||startList||\n${this.unfilteredList}\n||endList||`,
        adminList: this.selectedAdmin,
        smsText: this.smsText,
        verificationCode: this.smsVerificationCode,
        enableScheduledSMS: this.enableScheduledSMS,
        scheduleTimeStamp: this.scheduledSendDatetime.getTime()
      });
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to send test sms', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.HidePageLoading()
      
      this.smsVerificationCode = ''
      if (this.enableScheduledSMS) {
        this.$buefy.dialog.alert({ title: 'SMS is scheduled to send', message: `SMS will send at ${DDMMYYYY_HHii(this.scheduledSendDatetime)}`, type: 'is-info' })
        this.getQUEUE()
      } else {
        this.$buefy.dialog.alert({ title: 'SMS is sending now', message: 'SMS is sending now', type: 'is-info' })
      }
      
    },
    
    CheckLockStatus(){
      // console.info(`_this.LockStatus:`, this.LockStatus);
      if (this.LockStatus.unlocked === false) {
        this.isUnlocked      = false;
        this.UnlockedTimeMsg = "not unlocked";
        return;
      } 
      
      let curDate = new Date();
      if (parseInt(this.LockStatus.Locktime) < curDate.getTime()) {
        this.isUnlocked      = false;
        this.UnlockedTimeMsg = "expired";
      } else {
        if (this.isUnlocked === false) {
          this.getQUEUE()  
        }
        this.isUnlocked      = true;
        this.UnlockedTimeMsg = Calc_TimeLeftMsg(parseInt(this.LockStatus.Locktime));
        
      }
    },

    clearDateTime () {
      this.selected = null
    },
    
    loadQueueItem(queueItem){
      console.info(`queueItem:`, queueItem)
      this.testSMSSend = true
      this.smsVerificationCode = ''
      this.unfilteredList = queueItem.okNrList.join(`\n`)
      this.unfilteredListVerification = this.unfilteredList
      this.filteredSMSList = queueItem.okNrList
      this.smsText = queueItem.smsText
      this.scheduleTimeStamp = queueItem.sendAt
      this.enableScheduledSMS = true
      this.scheduledSendDatetime = new Date(queueItem.sendAt)
    },
    
    cancelQueueItem(queueItem){
      if (this.isUnlocked !== true) return false
      
      const _this = this;  
          
      this.$buefy.dialog.confirm({
        title: `are you sure you want to cancel?`,
        message: `cancel QUEUE AT: ${DDMMYYYY_HHii(new Date(queueItem.sendAt))} => ${queueItem.okNrList.length}x - ${ queueItem.smsText }`,
        
        onConfirm: async () => {
          _this.cancelQueueItemConfirm(queueItem)
        },
        cancelText: 'No',
        confirmText: 'YES, Cancel the queue Item'          
      })
    },
    
    async cancelQueueItemConfirm(queueItem){
      console.info(`cancelQueueItemConfirm:`, queueItem)
      if (this.isUnlocked !== true) return false
      
      await this.ShowPageLoading(100)      
      let response = await axios_api.post(`${API_ROOT}/monitor/sms/removeQueueItem`, {
        filename: queueItem.filename
      })
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to remove queue item', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.getQUEUE()
    }
    
  },
};
</script>

<style>
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight1{
      /* background-color: red; */
      font-size: 14px;
      height: 600px;
    }
  
    .inputheight2{
      /* background-color: red; */
      height: 560px;
      font-size: 11px;
    }
    
    .MyButton{
      /* background-color: red; */
      width: 96%;
      margin-left: 2%;
      margin-top: 2%;
    }
    
    textarea {
      resize: none;
    }
    
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight1{
      /* background-color: red; */
      font-size: 14px;
      height: 600px;
    }
    
    .inputheight2{
      /* background-color: red; */
      font-size: 14px;
      height: 300px;
    }
    
    .inputheight3{
      /* background-color: red; */
      font-size: 14px;
      height: 50px;
    }
    
    .inputheight4{
      /* background-color: red; */
      font-size: 14px;
      height: 150px;
    }
    .spacer{
      height: 50px;
      width: 100%;
    }
    
    .example_messages p{
      margin-top: 15px;
      padding: 2%;
    }
  

</style>